@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-card-image .card.image-full:before {
  border-radius: 0.5rem;
  opacity: 0.45;
}

.github-green {
  color: '#2DA44E' !important;
}
